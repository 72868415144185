:root {
  --cell-size: 12vmin;
  --perspective: 80vmin;
  --perspective-origin: center 50vh;
}

html,
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
}
article {
  display: flex;
  align-items: center;
  flex-direction: column;
  perspective: var(--perspective);
  -moz-perspective: var(--perspective);
  -webkit-perspective: var(--perspective);
  -o-perspective: var(--perspective);
  -ms-perspective: var(--perspective);
  perspective-origin: var(--perspective-origin);
  -moz-perspective-origin: var(--perspective-origin);
  -webkit-perspective-origin: var(--perspective-origin);
  -o-perspective-origin: var(--perspective-origin);
  -ms-perspective-origin: var(--perspective-origin);
}
.settings ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.unselectable {
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.cube {
  position: absolute;
  top: 50vh;
  margin: 0;
  transform-style: preserve-3d;
}
.face {
  position: absolute;
  width: calc(3 * var(--cell-size));
  height: calc(3 * var(--cell-size));
}
.cell {
  position: absolute;
  width: var(--cell-size);
  height: var(--cell-size);
  border: 0.2vw solid #000000;
  box-sizing: border-box;
}
.pif-top-0 {
  top: 0;
}
.pif-top-1 {
  top: var(--cell-size);
}
.pif-top-2 {
  top: calc(2 * var(--cell-size));
}
.pif-left-0 {
  left: 0;
}
.pif-left-1 {
  left: var(--cell-size);
}
.pif-left-2 {
  left: calc(2 * var(--cell-size));
}

.u-face {
  transform: translateY(-100%) rotateX(90deg) translateX(-50%);
}
.d-face {
  transform: rotateX(-90deg) translateX(-50%);
}
.b-face {
  transform: translateY(-50%) rotateY(90deg) translateX(50%) rotateY(90deg)
    translateX(50%);
}
.r-face {
  transform: translateY(-50%) rotateY(90deg);
}
.f-face {
  transform: translateY(-50%) rotateY(90deg) translateX(-50%) rotateY(-90deg)
    translateX(-50%);
}
.l-face {
  transform: translateY(-50%) translateX(-100%) rotateY(-90deg);
}

.red {
  background-color: rgb(255, 0, 0);
}
.blue {
  background-color: rgb(62, 0, 245);
}
.orange {
  background-color: rgb(255, 155, 0);
}
.green {
  background-color: rgb(0, 231, 0);
}
.yellow {
  background-color: rgb(247, 255, 0);
}
.white {
  background-color: white;
}
.gray {
  background-color: gray;
}
